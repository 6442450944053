import BaseService from '@/services/BaseService'

let resourceName = 'planStatFlow'

class PlanStatFlowService extends BaseService {
}

const planStatFlowService = new PlanStatFlowService(resourceName)

export default planStatFlowService
