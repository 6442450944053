<template>
  <el-form
    ref="elForm"
    :rules="rules"
    :model="planStatFlowModel"
    label-width="110px"
    >
    <el-form-item label="流程名称：" prop="flowName">
      <el-input
        type="text"
        v-model="planStatFlowModel.flowName">
      </el-input>
    </el-form-item>
    <el-form-item label="步骤名称：" prop="stepName">
      <el-input
        type="text"
        v-model="planStatFlowModel.stepName">
      </el-input>
    </el-form-item>
    <el-form-item label="后续步骤：" prop="nextUsers">
      <el-select v-model="planStatFlowModel.nextUsers" multiple clearable>
        <el-option
          v-for="identity in identities"
          :key="identity.identityGuid"
          :label="identity.identityName"
          :value="identity.identityGuid">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="身份：" prop="identityGuid">
      <el-select v-model="planStatFlowModel.identityGuid" clearable>
        <el-option
          v-for="identity in identities"
          :key="identity.identityGuid"
          :label="identity.identityName"
          :value="identity.identityGuid">
        </el-option>
      </el-select>
    </el-form-item>
    <template v-if="!planStatFlowModel.resourceId">
      <el-form-item label="所属公司：">
        <el-select v-model="companyGuid" clearable>
          <el-option
            v-for="company in companys"
            :key="company.companyGuid"
            :label="company.companyName"
            :value="company.companyGuid"
            >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属项目：" prop="projectGuid">
        <el-select v-model="planStatFlowModel.projectGuid" clearable>
          <el-option
            v-for="project in projects"
            :key="project.projectGuid"
            :label="project.projectName"
            :value="project.projectGuid"
            >
          </el-option>
        </el-select>
      </el-form-item>
    </template>
    <template v-else>
      <el-form-item label="所属项目：">
        <el-input
          disabled
          :value="planStatFlowModel.projectName">
        </el-input>
      </el-form-item>
    </template>
    <el-form-item label="报表是否显示：">
      <el-switch
        v-model.number="planStatFlowModel.displayReport"
        active-color="#13ce66"
        inactive-color="#ff4949"
        :active-value="1"
        :inactive-value="0"
        active-text="是"
        inactive-text="否">
      </el-switch>
    </el-form-item>
    <el-form-item label="排序：" prop="sortId">
      <el-input-number
        v-model="planStatFlowModel.sortId"
        :step="1"
        :min="0"
        :precision="0"
        step-strictly>
      </el-input-number>
    </el-form-item>
  </el-form>
</template>

<script>
import PlanStatFlowModel from '@/model/PlanStatFlowModel'
import identityService from '@/services/identityService'
import GetProject from '@/mixins/GetProject'

export default {
  name: 'FlowForm',
  mixins: [
    GetProject
  ],
  props: {
    planStatFlowModel: {
      type: PlanStatFlowModel,
      default () {
        return new PlanStatFlowModel()
      }
    }
  },
  watch: {
    companyGuid (newVal) {
      this.planStatFlowModel.projectGuid = ''
      this.projects = []
      newVal && this._getProjects(newVal)
    }
  },
  data () {
    return {
      companyGuid: '',
      identities: [],
      rules: {
        flowName: [
          {
            required: true,
            message: '请输入流程名称',
            trigger: 'blur'
          }
        ],
        stepName: [
          {
            required: true,
            message: '请输入步骤名称',
            trigger: 'blur'
          }
        ],
        nextUsers: [
          {
            required: true,
            message: '请选择后续步骤',
            trigger: 'blur'
          }
        ],
        identityGuid: [
          {
            required: true,
            message: '请选择身份',
            trigger: 'blur'
          }
        ],
        projectGuid: [
          {
            required: true,
            message: '请选择项目',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    validate () {
      return this.$refs.elForm.validate
    },
    _getIdentities () {
      identityService.list()
        .then(res => {
          if (res.data.code === 1) {
            this.identities = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          window.concole.log(err.request.response)
        })
    }
  },
  created () {
    this._getIdentities()
  }
}
</script>
