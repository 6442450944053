import utility from '@/common/utility'
import auth from '@/common/auth'

export default class PlanStatFlowModel {
  constructor (props) {
    if (props) {
      this.resourceId = props.flowGuid
      this.flowGuid = props.flowGuid
      this.flowName = props.flowName
      this.stepName = props.stepName
      this.nextUsers = props.nextUsers
      this.identityGuid = props.identityGuid
      this.identityName = props.identityName
      this.projectGuid = props.projectGuid
      this.projectName = props.projectName
      this.displayReport = props.displayReport
      this.staffName = props.staffName
      this.sortId = props.sortId
      this.createUserGuid = props.createUserGuid
      this.createTime = props.createTime
    }
    this.init()
  }

  init () {
    if (!this.resourceId) this.resourceId = ''
    if (!this.flowGuid) this.flowGuid = ''
    if (!this.flowName) this.flowName = ''
    if (!this.stepName) this.stepName = ''
    if (!this.nextUsers) this.nextUsers = ''
    if (!this.identityGuid) this.identityGuid = ''
    if (!this.identityName) this.identityName = ''
    if (!this.projectGuid) this.projectGuid = ''
    if (!this.projectName) this.projectName = ''
    if (!this.displayReport) this.displayReport = ''
    if (!this.staffName) this.staffName = ''
    if (!this.sortId) this.sortId = 0
    if (!this.createUserGuid) this.createUserGuid = auth.getUserInfo().userGuid
    if (!this.createTime) this.createTime = utility.getCurrentTime()
  }

  generatePrimaryKey () {
    this.flowGuid = utility.getUuid()
    this.resourceId = this.flowGuid
  }
}
